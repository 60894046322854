.active {
  font-weight: bold;
  font-stretch: expanded;
  color: #4E2766;
}

body {
}

.bg-dark-navbar {
  background-color: #313131;
}

.carousel {
  margin: auto;
  max-width: 60%;
  max-height: 60%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23321941' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23321941' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.fa-instagram {
  background: #F8F1F1;
  color: #4E2766;
  size: 90px;
}

button:disabled{
  border: 1px solid #000000;
  background-color: #313131;
  color: #000000;
}

@media (max-width: 800px){
  .heading{font-size: 10em;}
}

@media (max-width: 480px){
  .heading{font-size: 1.2em;}
}

@media (max-width: 375px){
 .heading{font-size: 1em;}
}

